require('./bootstrap');
import './bootstrap';
import 'laravel-datatables-vite';
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-select-bs4');
require('datatables.net-searchpanes-bs4');

$(function() {

    // Start transitions
    $('.no-transition').each((i, e) => {
        $this = $(e)
        setTimeout(() => {
            $this.removeClass("no-transition");
        }, $this.data('preload') || 100);
    });

    // Setup tooltips
    $("[data-toggle=tooltip]").tooltip()

    // Remove the current form animation
    $('form.form-action button[type="submit"]').removeClass('btn-loading')

    // Add animation to the button on form submit
    $('form.form-action').on('submit', function(e) {
        $(this).find('button[type="submit"]').first().addClass('btn-loading')
    });

    // Dashboard
    $(".toggle-password").on('click', function(e){
        $(this).toggleClass('active')
        let active = $(this).hasClass('active')
        let parent = $(this).closest('div')
        parent.find('input').prop('type', active ? 'text' : 'password')
        parent.find('.icon').toggleClass('d-none')
    })

    // Sidebar
    $("#sidenavToggler").on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('active')

        $("body").toggleClass("sidebar-toggled")
        $(".sidebar").toggleClass("toggled")

        if ($(".sidebar").hasClass("toggled"))
            $('.sidebar .collapse').collapse('hide')
    })

    $("#sidebarSlide").on('click', function(e){
        $(".sidebar").toggleClass("active")
    })

    $("#sidebarOverlay").on('click', function(e){
        $(".sidebar").removeClass("active")
    })
})
