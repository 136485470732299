// window._ = require('lodash');
require('bootstrap');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-select-bs4');
require('datatables.net-searchpanes-bs4');
import { debounce,
    isString,
    isObject,
    isArray,
    isEmpty,
    isNull,
    isUndefined,
    includes,
    upperFirst
} from 'lodash';

window._ = {
    debounce,
    isString,
    isObject,
    isArray,
    isEmpty,
    isNull,
    isUndefined,
    includes,
    upperFirst
};

/**
 * https://www.carlosroso.com/notyf/
 */
import { Notyf } from 'notyf';
let notyfTypes = [{
        type: 'warning',
        background: '#ffae36'
    },{
        type: 'info',
        background: '#11cdef'
    },{
        type: 'show',
        background: '#172b4d'
    },{
        type: 'success',
        background: '#00ac69',
        icon: false,
    },{
        type: 'error',
        background: '#f5365c',
        icon: false,
    }
];

window.notyf = new Notyf({
    duration: 5000,
    dismissible: true,
    types: notyfTypes
});

notyfTypes.forEach(item => {
    window.notyf[item.type] = (options) => {

        if(!_.isString(options) && !_.isObject(options))
            throw 'Invalid option type'

        if(_.isString(options)){
            options = {
                type: item.type,
                message: options
            }
        }
        else{
            options.type = item.type
        }

        notyf.open(options);
    }
});

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

    //Requerido Exportar Excel Datatable
    window.JSZip = require('jszip')

    //Requerido Exportar Pdf Datatable
    window.pdfMake = require('pdfmake')

    const pdfFonts = require('pdfmake/build/vfs_fonts')
    window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

    /**
     * https://github.com/knownasilya/jquery-highlight
     */
    require('jquery-highlight');

    /**
     * https://datatables.net/
     */
    require('datatables.net');
    require('datatables.net-dt');
    require('datatables.net-buttons');
    require('datatables.net-buttons-dt');
    require('datatables.net-buttons/js/buttons.colVis.js');
    require('datatables.net-buttons/js/buttons.html5.js');
    require('datatables.net-buttons/js/buttons.print.js');
    require('datatables.net-responsive');
    require('datatables.net-responsive-dt');
    require('datatables.net-select');
    require('datatables.net-select-dt');
    require('jquery-datatables-checkboxes');
    require('./plugins/dataTables.searchHighlight.min');
    require('./plugins/dataTable.defaults');

    /**
     * https://select2.org/
     */
    require('select2');
    require('./plugins/select2.defaults');

    /**
     * http://t1m0n.name/air-datepicker/docs/
     */
    require('air-datepicker');
    require('air-datepicker/dist/js/i18n/datepicker.en');

    /**
     * https://craftpip.github.io/jquery-confirm/
     */
    require('jquery-confirm');
    require('./plugins/jconfirm.defaults');

    /**
     * JQuery WebForm
     */
    require('./plugins/jquery.webForm');

    /**
     * https://github.com/flaute/bootstrap-colorselector
     */
    require('./plugins/bootstrap-colorselector.min');

} catch (e) {}

/**
 * http://grsmto.github.io/simplebar/
 *
 */
require('simplebar');

/**
 * https://apexcharts.com/
 */
import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

/**
 * https://fullcalendar.io
 */

import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
// import esLocale from '@fullcalendar/core/locales/es';

window.initFullCalendar = (element, options) => {
    options = options || {};
    options.plugins = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin];
    // options.locales = [esLocale];
    // options.locale  = 'es';
    const fCalendar = new Calendar(element, options);

    fCalendar.render();
    return fCalendar;
};

/**
 * https://swiperjs.com/
 */
import Swiper from 'swiper';
window.Swiper = Swiper;

/**
 * http://davidshimjs.github.io/qrcodejs/
 */
 import qrcodejs from 'davidshimjs-qrcodejs';
 window.QRCode = qrcodejs;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the 'XSRF' token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/* Including Cropper */
import Cropper from 'cropperjs';
window.Cropper = Cropper;

/* Including Sweetalert */
import Swal from 'sweetalert2';
window.Swal = Swal;
