
jconfirm.pluginDefaults.draggable = false;
jconfirm.pluginDefaults.animateFromElement = false;
jconfirm.pluginDefaults.theme = "bootstrap";
jconfirm.pluginDefaults.closeIcon = true;
jconfirm.pluginDefaults.backgroundDismiss = true;
jconfirm.pluginDefaults.columnClass = 'col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1';
jconfirm.pluginDefaults.defaultButtons = {
    Ok: {
        btnClass: 'btn btn-primary',
        keys: ['enter'],
        text: 'Ok',
        action: function(){
        }
    },
    Close: {
        keys: ['esc'],
        text: 'Cancel',
        action: function(){
        }
    }
};

window.$.confirm.delete = function (title, content, action, cancel = null) {
    this({
        title: title,
        content: content,
        // autoClose: 'Cancel|8000',
        // backgroundDismiss: false,
        buttons: {
            Delete:{
                text: `<div class="center-all"><i class="ri-delete-bin-line mr-2"></i> Delete</div>`,
                btnClass: 'btn btn-danger',
                action: action
            },
            Cancel: {
                text: 'Cancel',
                keys: ['esc'],
                action: cancel
            },
        },
        onContentReady: function(){
            window.renderFeatherIcons();
        }
    });
};
