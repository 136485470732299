
// Evita la alerta de errores
$.fn.dataTable.ext.errMode = 'none';

function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
}

var buttonCommon = {
    exportOptions: {
        columns: ':not(.ignore-column)',
        format: {
            body: function ( data, row, column, node ) {

                if(data == null || typeof data === 'undefined')
                    return data;

                if(_.includes(data, "print-model"))
                    return $(data).find(".print-model").text().trim();

                return extractContent(data).trim();
            }
        }
    }
};

// Asigna los valores por defecto que tendra el Datatable
$.extend(true, $.fn.dataTable.defaults, {
    searchHighlight: true,
    processing: true,
    serverSide: true,
    searchDelay: 350,
    dom: "Bfrtip",
    scrollX: true,
    stateSave: true,
    autoWidth: true,
    scrollY: 'calc(100vh - 25rem)', 
    pageLength: 25,
    lengthMenu: [[25, 50, 100, 200, 500], ["25 rows", "50 rows", "100 rows", "200 rows", "500 rows"]],
    buttons: [
        {
            extend: 'pageLength',
            fade: 150,
        },
        {
            extend: 'collection',
            fade: 150,
            text: 'Export',
            buttons: [
                $.extend( true, {}, buttonCommon, {
                    extend: 'csvHtml5',
                }),
                $.extend( true, {}, buttonCommon, {
                    extend: 'pdfHtml5',
                }),
                $.extend( true, {}, buttonCommon, {
                    extend: 'excelHtml5',
                })
            ]
        },
        {
            extend: 'print',
            text: `Print`,
        },
    ],
    initComplete: function( settings, json ) {
        var table   = new $.fn.dataTable.Api(settings);
        var wrapper = $(table.table().node()).closest('.dataTables_wrapper');
        
        //debouce
        if(settings.searchDelay > 0){

            var timeout = null
            var delay = settings.searchDelay
            var input = wrapper.find('div.dataTables_filter input')

            input.off('keyup.DT input.DT');
            input.on('keyup', function() {

                var search = input.val();
                clearTimeout(timeout);

                timeout = setTimeout(function() {
                    table.search(search || "").draw();
                }, delay);
            });
        }
    }
});
 