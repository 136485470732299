/**
 * --------------------------------------------------------------------------
 * Jquery WebForm V1.0.2
 * Karloz Rivas
 * --------------------------------------------------------------------------
 */

 import axios from 'axios';

 (function($) {

     /**
      * El Constructor del WebForm
      * @param {DOM Element} element El elemento DOM al que se aplicara el plugin
      * @param {Object} options Opciones de configuracion
      */
     function WebForm(element, options) {

         this._defaults = {
             data: null,
             submit: {
                 method: null,
                 action: null,
             }
         };

         this._data = null;

         this._errors = null;

         // Instancia del elemento
         this.el = element;

         // Instancia del elemento como objeto JQuery
         this.$el = $(element);

         // Combina las configuraciones del objeto
         this.options =  this._clone($.extend({}, $.fn.webForm.defaults, options));

         if(this._isEmpty(this.options.submit.method))
             this.options.submit.method = this.$el.attr('method') || this.$el.data('method') || null;

         if(this._isEmpty(this.options.submit.action))
             this.options.submit.action = this.$el.attr('action') || this.$el.data('action') || null;

         // Asigna la accion y metodo por defecto del formulario
         // creando una copia del objeto principal
         this._defaults.submit = this._clone(this.options.submit);

         // Asigna una copia de la estructura de datos del formulario
         // para porder hacer el mapeo con de los elementos
         this._data = this._clone(this.options.data);

         /**
          * Permite realizar un peticion HTTP
          *
          * @example
          * $('#form').webForm('request', 'POST', 'https://api.website.com');
          *
          * @param  {String} method Nombre del metodo GET, POST, PUT|PATCH, DELETE (mayuscula o miniscula)
          * @param  {String} url Ruta de destino de la peticion
          * @param  {Object} override Objeto que permite sobrescribir un valor del data en la peticion. Ejemplo { name: 'nuevo nombre'}
          * @param  {Object} config Objeto de configuracion de axios
          * @return {Promise}
          */
         this.request = function(method, url, override = null, config = {}) {

             if(this._isEmpty(method)){
                 console.error("[WEBFORM]: The method name is required.")
                 return;
             }

             if(this._isEmpty(url)){
                 console.error("[WEBFORM]: The url is required.")
                 return;
             }

             if(this._isEmpty(config.headers)){
                 config.headers = {};
             }

             let _this = this;
             let _data = _this.data()
             method    = method.toLowerCase()

             if(!_this._isEmpty(override))
                 _data = $.extend({}, _data, override)

             _this._startRequest()
             _this._triggerEvent('onSubmit', _data)

             switch (_this.options.type) {
                 case WF_FORM_DATA:
                     let formData = new FormData();
                     Object.keys(_data).forEach(key => {
                         if(typeof _data[key] !== 'undefined' && _data[key] !== null)
                             formData.append(key, _data[key]);
                     });

                     _data = formData;
                     config.headers['Content-Type'] = 'multipart/form-data';
                     break;

                 case WF_FORM_URLENCODED:
                     let urlSearchParams = new URLSearchParams();
                     Object.keys(_data).forEach(key => {
                         urlSearchParams.append(key, _data[key]);
                     });

                     _data = urlSearchParams;
                     config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                     break;

                 default:
                     config.headers['Content-Type'] = 'application/json';
                     break;
             }

             var data   = method !== 'get' ? _data : null
             var params = method === 'get' ? _data : null

             return new Promise((resolve, reject) => {
                 axios.request({ url, method, data, params, ...config })
                 .then(response => {
                     _this._endRequest()
                     _this._triggerEvent('onSuccess', response)
                     resolve(response)
                 })
                 .catch(error => {
                     _this._endRequest()
                     _this._triggerEvent('onFail', error)

                     if (error.isAxiosError && !_this._isEmpty(error.response))
                         _this.setErrors(error.response.data)

                     // reject(error)
                 })
             })
         }

         /**
          * Permite realizar un peticion HTTP GET
          *
          * @example
          * $('#form').webForm('get', 'https://api.website.com');
          *
          * @param  {String} url Ruta de destino de la peticion
          * @param  {Object} override Objeto que permite sobrescribir un valor del data en la peticion. Ejemplo { name: 'nuevo nombre'}
          * @param  {Object} config Objeto de configuracion de axios
          * @return {Promise}
          */
         this.get = function(url, override = null, config = {}) {
             return this.request('get', url, override, config)
         }

         /**
          * Permite realizar un peticion HTTP POST
          *
          * @example
          * $('#form').webForm('post', 'https://api.website.com');
          *
          * @param  {String} url Ruta de destino de la peticion
          * @param  {Object} override Objeto que permite sobrescribir un valor del data en la peticion. Ejemplo { name: 'nuevo nombre'}
          * @param  {Object} config Objeto de configuracion de axios
          * @return {Promise}
          */
         this.post = function(url, override = null, config = {}) {
             return this.request('post', url, override, config)
         }

         /**
          * Permite realizar un peticion HTTP PUT
          *
          * @example
          * $('#form').webForm('put', 'https://api.website.com');
          *
          * @param  {String} url Ruta de destino de la peticion
          * @param  {Object} override Objeto que permite sobrescribir un valor del data en la peticion. Ejemplo { name: 'nuevo nombre'}
          * @param  {Object} config Objeto de configuracion de axios
          * @return {Promise}
          */
         this.put = function(url, override = null, config = {}) {
             return this.request('put', url, override, config)
         }

         /**
          * Permite realizar un peticion HTTP DELETE
          *
          * @example
          * $('#form').webForm('delete', 'https://api.website.com');
          *
          * @param  {String} url Ruta de destino de la peticion
          * @param  {Object} override Objeto que permite sobrescribir un valor del data en la peticion. Ejemplo { name: 'nuevo nombre'}
          * @param  {Object} config Objeto de configuracion de axios
          * @return {Promise}
          */
         this.delete = function(url, override = null, config = {}) {
             return this.request('delete', url, override, config)
         }

         /**
          * Devuelve la estructura de datos del webForm
          *
          * @example
          * $('#form').webForm('data');
          *
          * @return {Promise}
          */
         this.data = function() {
             return this._data
         }

         /**
          * Permite asignar valores a la estructura de datos del webForm
          *
          * @example
          * $('#form').webForm('fill', {name: 'Nuevo Nombre'});
          *
          * @param {Object} data Objeto de datos
          */
         this.fill = function(data) {
             Object.keys(this._defaults.data).forEach(selector => {
                 if(typeof data[selector] !== 'undefined'){
                     this._data[selector] = data[selector]

                     let elements = this._find(selector);
                     if(elements.length !== 0)
                         this._setElementValue(elements, data[selector]).trigger("change");
                 }
             })
         }

         /**
          * Permite reestablecer los valores de datos, metodos y accion a como se encontraban cuando
          * fue inicializado, tambien eliminar los errores del DOM
          *
          * @example
          * $('#form').webForm('reset');
          */
         this.reset = function() {
             this.clear();
             this.options.submit.method = this._defaults.submit.method;
             this.options.submit.action = this._defaults.submit.action;
             this.fill(this._defaults.data);
         }

         /**
          * Permite eliminar los errores del DOM
          *
          * @example
          * $('#form').webForm('clear');
          */
         this.clear = function() {
             this._errors = []
             Object.keys(this._data).forEach(selector => {

                 let elements = this._find(selector);
                 if(elements.length == 0)
                     return;

                 elements.removeClass(this.options.errorElementClass);
             });

             this.$el.find(`.webform-alert, .webform-error`).remove();
         }

         /**
          * Devuelve el objeto de errores que genero cuando se
          * ejecutaba la peticion
          *
          * @example
          * $('#form').webForm('errors');
          *
          * @returns {Array|Object} Objeto de errores
          */
         this.errors = function() {
             return this._errors
         }

         /**
          * Permite asignar un objeto de errores al formulario y si la estructura
          * es correcta, estos errores se renderizaran en los elementos del DOM
          *
          * @example
          * $('#form').webForm('setErrors', { message: 'Ha ocurrido un error'});
          * $('#form').webForm('setErrors', { name: ['El nombre es invalido', 'Debe contener mayusculas'] });
          * $('#form').webForm('setErrors', 'Debe llenar todos los campos');
          *
          * @param {Array|Object} errorObject Objeto de errores
          */
         this.setErrors = function(errorObject) {
             errorObject  = (typeof errorObject === 'string') ? [errorObject] : errorObject
             errorObject  = errorObject || []
             this._errors = errorObject
             this._setDomErrors();
         }

         /**
          * Permite configurar el metodo y la accion de la peticion, estos
          * se aplicaran la proxima vez que el formulario haga submit.
          * IMPORTANTE: Estos datos se restauran al valor por defecto al llamar el metodo "reset"
          *
          * @example
          * $('#form').webForm('setSubmit', 'POST', 'https://api.website.com');
          *
          * @param {String} method Nombre del metodo GET, POST, PUT|PATCH, DELETE (mayuscula o miniscula)
          * @param  {String} url Ruta de destino de la peticion
          */
         this.setSubmit = function(method, action) {
             this.options.submit.method = method;
             this.options.submit.action = action;
         }

         /**
          * Devuelve el nombre del metodo actual del formulario
          *
          * @example
          * $('#form').webForm('getMethod');
          *
          * @returns {String} Nombre del metodo GET, POST, PUT|PATCH, DELETE
          */
         this.getMethod = function() {
             return this.options.submit.method;
         }

         /**
          * Devuelve el nombre la url de destino del formulario
          *
          * @example
          * $('#form').webForm('getAction');
          *
          * @returns {String} Nombre de la url de destino
          */
         this.getAction = function() {
             return this.options.submit.action;
         }

         /**
          * Permite eliminar la instancia del plugin en el elemento
          *
          * @example
          * $('#form').webForm('destroy');
          */
         this.destroy = function() {

             this.clear();

             // Remueve el evento del formulario
             this.$el.off('submit.webForm');

             // Remueve los eventos de los elementos del formulario
             let evens = this._listeners.join('.webForm ')+'.webForm';
             Object.keys(this._data).forEach(selector => {
                 let elements = this._find(selector);
                 elements.off(evens);
             });

             // Elimina la instancia del webForm
             this.$el.data('WebForm', undefined)
         }

         // Inicializa la instancia del plugin
         this._init();
     }

     /**
      * Crea un prototipo del objeto para manejo de metodos privados
      */
     WebForm.prototype = {

         _listeners: [
             'keyup',
             'keypress',
             'blur',
             'input',
             'change'
         ],

         /**
          * Permite inicializar los datos, eventos y configuracion inicial
          * que se utilizaran dentro del plugin
          *
          */
         _init: function() {

             var _this   = this;
             var defData = _this._clone(_this._data);

             // Si no esta definido la estructura de datos la obtiene de los elementos
             _this.$el.find('[data-model]').each((i, component) => {
                 let source = $(component).data('model');
                 if(!_this._isEmpty(source)){
                     if(_this._isEmpty(_this._data))
                         _this._data = {}

                     // Crear la propiedad con el nombre del modelo
                     let properties = source.split('.');
                     var recursive  = _this._data;
                     properties.map((k, i, values) => {
                         let assign = recursive == null || typeof recursive === 'undefined' || !recursive.hasOwnProperty(k);
                         recursive  = (recursive[k] = (i == values.length - 1 ? null : assign ? {} : recursive[k]))
                     });
                 }
             });

             if(_this._isEmpty(_this._data)){
                 console.error("[WEBFORM]: Data source is undefined, please set 'data' property on WebForm initialization or assign the 'data-model' in the form DOM elements", _this.$el);
                 return;
             }

             let evens = _this._listeners.join('.webForm ')+'.webForm';
             let properties = _this._propertiesToArray(_this._data);
             properties.forEach(selector => {

                 let elements = _this._find(selector);
                 if(elements.length == 0)
                     return;

                 elements.attr('data-model', selector);
                 elements.data('model', selector);

                 // Asigna los eventos de interaccion para asignar los datos al form
                 elements.on(evens, function(e) {

                     // e.type = tipo de evento ejecutado
                     var model = $(e.target).data('model')
                     let nodes = _this._find(model);
                     let value = _this._getElementValue(nodes);
                     _this._setNestedValue(model, value);
                 });

                 //Asigna el valor a los elementos
                 if(!_this._isEmpty(_this._data[selector]))
                     _this._setElementValue(elements, _this._data[selector]);

                 // Si el objeto data no tiene un valor predeterminado para
                 // la propiedad, asigna el valor del elemento DOM
                 if(defData == null || typeof defData[selector] === 'undefined'){
                     let value = _this._getElementValue(elements);
                     _this._setNestedValue(selector, value);
                 }
             });

             // Asigna el valor por defecto del data
             _this._defaults.data = _this._clone(_this._data);

             // Intercepta el evento submit en el formulario
             _this.$el.on('submit.webForm', (e) => {

                 e.preventDefault();
                 e.params = {
                     data: _this._data
                 };

                 _this._triggerEvent("onFormSubmit", e);

                 var method = _this.options.submit.method;
                 var action = _this.options.submit.action;

                 if(!_this._isEmpty(method) && !_this._isEmpty(action))
                     return _this.request(method.toLowerCase(), action)

                 console.error("[WEBFORM]: Form action or form method are undefined or empty.")
                 return false;

             })
         },

         /**
          * Crea una copia del objeto
          */
         _clone: function(object){
             return JSON.parse(JSON.stringify(object));
         },

         /**
          * Verifica de manera basica si un objeto esta vacio
          */
         _isEmpty: function(value) {
             return value == null || (value || '') === '' || typeof value === 'undefined';
         },

         /**
          * Verifica si un objeto es un Array
          */
         _isArray: function(object){
             return typeof object === "object" && typeof object.length !== "undefined";
         },

         /**
          * Obtiene los elementos relacionado al "data" dando prioridad a los que tengan
          * asignado el atributo "[data-model]"
          */
         _find: function(selector) {
             let elements = this.$el.find(`[data-model="${selector}"]`);
             if(elements.length == 0)
                 elements = this.$el.find(`#${selector}, [name="${selector}"]`);

             return elements;
         },

         /**
          * Permite asignar el valor a los elementos del DOM
          */
         _setElementValue: function(elements, source) {

             try {

                 // Cuando se trata de un radio, checkbox y select multiple
                 // los valores se deben asignar en formato "Array"
                 let multiElements = elements.filter('[type="radio"], [type="checkbox"], select[multiple]');
                 if(multiElements.length > 0){

                     // Marca los elementos que aceptan valores booleanos
                     if(typeof source === 'boolean'){
                         let checkboxs = elements.filter('[type="checkbox"][data-boolean="true"]');
                         if(checkboxs.length > 0){
                             checkboxs.prop('checked', source);
                             return;
                         }
                     }

                     multiElements.val(!this._isArray(source) ? [source] : source);
                     return;
                 }

                 elements.val(source);
             } finally {
                 return elements;
             }
         },

         /**
          * Obtiene el valor de los elementos del DOM
          */
         _getElementValue: function(elements) {

             // Si es tipo archivo se devuelve el objeto binario
             let files = elements.filter('[type="file"]');
             if(files.length > 0){
                 return files[0].files.length > 1 ? files[0].files : files[0].files[0] || null;
             }

             // Cuando se trata de elementos que soporten la propiedad "Checked"
             // se debe evaluar cual de estos elementos esta selccionado
             let radios = elements.filter('[type="radio"]');
             if(radios.length > 0)
                 return radios.filter(':checked').val() || null;

             // En el caso de los checkboxs puede haber mas de uno seleccionado
             let checkboxs = elements.filter('[type="checkbox"]');
             if(checkboxs.length > 0){

                 if(checkboxs.length == 1 && (checkboxs.data('boolean') == 'true' || checkboxs.data('boolean') == true)){
                     return checkboxs.is(':checked');
                 }

                 var checked = checkboxs.filter(':checked');
                 if(checked.length == 0)
                     return null;

                 var list = [];
                 checked.each((i, component) => {
                     list.push($(component).val());
                 })

                 return list;
             }

             // En el caso de utilizar formData se obtiene el valor del File
             if(this.options.formData){
                 debugger
                 let fileInputs = elements.filter('[type="file"]');
                 if(fileInputs.length > 0){
                     return fileInputs[0].files[0];
                 }
             }

             return elements.val();
         },

         /**
          * Asigna los errores a los elementos del DOM
          */
         _setDomErrors: function() {

             var _this  = this;
             var errors = [];

             if(_this._isEmpty(_this._errors))
                 return;

             errors = _this._errors.hasOwnProperty('errors') ? _this._errors.errors : _this._errors;
             errors = _this._errors.hasOwnProperty('error')  ? _this._errors.error  : errors;

             if(errors.hasOwnProperty('message')){
                 if(_this._isArray(errors))
                     errors.push(errors.message || _this.options.errorMessage)
                 else
                     errors = [errors.message || _this.options.errorMessage]
             }

             if(errors.length == 1){
                 _this.$el.prepend(`<div class="${_this.options.errorAlertClass} webform-alert">${ errors[0] || _this.options.errorMessage }</div>`)
                 return;
             }

             Object.keys(_this._data).forEach(selector => {

                 let elements = _this._find(selector);
                 if(elements.length == 0 || !errors.hasOwnProperty(selector))
                     return;

                 elements.addClass(_this.options.errorElementClass);
                 var hasRender     = !_this._isEmpty(elements.data('error'));
                 var renderMessage =  hasRender ? $(`#${elements.data('error')}`) : elements;
                 if(renderMessage.length == 0) {
                     console.warn(`[WEBFORM]: The element '#${elements.data('error')}' was not found, the error message was not rendered.`)
                 } else {
                     var errorSet = errors[selector];
                     if(!_this._isArray(errorSet)){
                         let template = `<span class="${_this.options.errorMessageClass} webform-error">${errorSet}</span>`;
                         if(hasRender)
                             renderMessage.append(template);
                         else
                             renderMessage.after(template);
                     } else {
                         errors[selector].forEach((error, i) => {
                             let template = `<span class="${_this.options.errorMessageClass} webform-error">${error}</span>`;
                             if(hasRender)
                                 renderMessage.append(template);
                             else
                                 renderMessage.after(template);
                         });
                     }
                 }
             })
         },

         /**
          * Permite ejecutar un evento en el formulario
          */
         _triggerEvent: function(event, args) {

             var callback = this.options[event];
             if (typeof this.options[event] === 'function')
                 callback(args)

             let eventObject   = $.Event(`webform:${ event.replace('on', '').toLowerCase() }`);
             eventObject.state = args;

             this.$el.trigger(eventObject);
         },

         /**
          * Inicializa el proceso de la peticion HTTP
          */
         _startRequest: function() {
             this.clear()
             this.$el.addClass(this.options.formLoadingClass);
             this.$el.find(`[type="submit"]`).addClass(this.options.buttonLoadingClass);
         },

         /**
          * Finaliza el proceso de la peticion HTTP
          */
         _endRequest: function() {
             this.$el.removeClass(this.options.formLoadingClass);
             this.$el.find(`[type="submit"]`).removeClass(this.options.buttonLoadingClass);
         },

         /**
          * Tranforma una objeto en un arreglo de propiedades
          */
         _propertiesToArray(obj) {
             const isObject = val =>
                 typeof val === 'object' && !Array.isArray(val) && val != null && typeof val !== 'undefined';

             const addDelimiter = (a, b) =>
                 a ? `${a}.${b}` : b;

             const paths = (obj = {}, head = '') => {
                 return isObject(obj) ? Object.entries(obj)
                     .reduce((product, [key, value]) =>
                         {
                             let fullPath = addDelimiter(head, key)
                             return isObject(value) ?
                                 product.concat(paths(value, fullPath))
                             : product.concat(fullPath)
                         }, []) : {};
             }

             return paths(obj);
         },

         _getNestedValue(desc){
             var obj = this._data;
             var arr = desc.split(".");
             while(arr.length && (obj = obj[arr.shift()]));
                 return obj;
         },

         _setNestedValue(desc, value){
             var obj  = this._data;
             var arr  = desc.split(".");
             var last = arr.pop();
             while(arr.length && (obj = obj[arr.shift()]));
                 obj[last] = value;
         }
     };

     /**
      * Inicializacion de WebForm
      *
      * @example
      * $('#form').webForm({
      *     errorInputClass: 'my-class other-class',
      * });
      */
     $.fn.webForm = function(options) {

         var args  = arguments;
         var forms = this.filter('form')

         // Valida que existan elementos "form" en la seleccion
         if(forms.length == 0) {
             console.warn("[WEBFORM]: Forms not found, please assign the WebForm plugin to a valid <form>")
             return;
         }

         // Verifica si se trata de una inicializacion del componente
         if (typeof options === 'undefined' || typeof options === 'object') {

             // Crea una instancia del plugin por cada uno de los componentes
             // y almacena la instancia en la propiedad "data" del elemento
             return forms.each(function() {
                 if (!$(this).data('WebForm')){
                     let instance = new WebForm(this, options);
                     $(this).data('WebForm', instance)
                 }
             });

             // Devuelve las instancias del plugin
             // return _instances.length > 1 ? _instances : _instances[0]
         }

         // Valida que se trate de una peticion de un metodo valida
         if(typeof options !== 'string' || options[0] === '_' && options === 'init')
             return;

         let _args = Array.prototype.slice.call(args, 1);

         // Si no contiene argumentos se trata de una funcion que devuelve datos
         // Toma la instancia del primer elemento y devuelve el valor
         if($.inArray(options, $.fn.webForm.getters) !== -1){
             let instance = $.data(forms[0], 'WebForm');
             // Verifica que la peticion sea valida
             if (instance instanceof WebForm && typeof instance[options] === 'function')
                 return instance[options].apply(instance, _args);

             // Si no se trata de una funcion valida realiza un "return"
             return;
         }

         // Invoca el metodo en cada instancia relacionada al elemento
         // Devuelve una lista de objetos JQuery
         return forms.each(function() {
             var instance = $.data(this, 'WebForm');
             if (instance instanceof WebForm && typeof instance[options] === 'function')
                 instance[options].apply(instance, _args);
         });
     };

     // Body types
     window.WF_JSON = 'json';
     window.WF_FORM_DATA = 'form-data';
     window.WF_FORM_URLENCODED = 'form-urlencoded';

     /**
      * Nombre de los metodos que devuleven datos.
      * @type {Array}
      */
     $.fn.webForm.getters = [
         'request',
         'get',
         'post',
         'put',
         'delete',
         'data',
         'errors',
         'getMethod',
         'getAction'
    ];

     /**
      * Configuracion por defecto
      */
     $.fn.webForm.defaults = {
         // Datos
         data: null,
         // json, form-data, form-urlencoded, binary
         type: WF_JSON,
         submit: {
             method: null,
             action: null,
         },

         // Configuracion
         formLoadingClass: 'from-loading',
         buttonLoadingClass: 'btn-loading',
         errorAlertClass: 'alert alert-danger',
         errorElementClass: 'is-invalid',
         errorMessageClass: 'invalid-feedback',
         errorMessage: "An unexpected error has occurred.",
         ignoreErrors: [500],

         // Eventos
         onSubmit:     null, // webform:submit
         onFormSubmit: null, // webform:formsubmit
         onSuccess:    null, // webform:success
         onFail:       null, // webform:fail
     };

 })(jQuery);
